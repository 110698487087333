import(/* webpackMode: "eager", webpackExports: ["PanelOne","PanelTwo","PanelThree"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/components/AdsFructidor/HomePage/ContentAdsPanel.tsx");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/components/AdsFructidor/HomePage/LeftAdsPanel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/components/BuyersAlert.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/components/Categories.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/components/CompaniesOutlet/CompaniesOutlet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/components/NewsLetter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/components/NewsOutlet/NewsOutlet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241015060508/app/[lng]/components/SalesOffers.tsx");
